import arrow from '@/assets/svgs/arrow-green.svg';
import infoIcon from '@/assets/svgs/info-gray.svg';
import LeaderBoardPage from '@/components/Leaderboard';
import LoadingIcon from '@/components/Loading';
import { MTokenInfo, MyPoint } from '@/components/SubNav';
import ENDPOINTS from '@/constants/apiEndpoints';
import { useContracts } from '@/contexts/ContractsContext';
import { useCheckNetwork } from '@/hooks/useCheckNetwork';
import { useResponsive } from '@/hooks/useResonposive';
import useTransaction from '@/hooks/useTransaction';
import { useClaimableToken } from '@/store/useClaimableToken';
import { usePointsStatus } from '@/store/usePointsStatus';
import useUserInfo from '@/store/useUserInfo';
import { useUserRank } from '@/store/useUserRank';
import { fetcher } from '@/utils/request';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { notification, Popover } from 'antd';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useAccount, useChainId } from 'wagmi';
import Boxes from './Boxes';
import ExtraPoints from './ExtraPoints';

const CLAIM_START_TIME = new Date('2024-12-02T14:00:00Z'); // third round manta rewards distribution

const isBeforeClaimStart = () => new Date() < CLAIM_START_TIME;

function Points() {
  const { isMobile } = useResponsive();
  const { open } = useWeb3Modal();
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [handleClaimLoading, setHandleClaimLoading] = useState(false);
  const [claimedStatus, setClaimedStatus] = useState(false);
  const [claimPaused, setClaimPaused] = useState(false);
  const showConnectModal = () => {
    window.isFromConnectWalletToContinueBtn = true;
    open();
  };

  const { address } = useAccount();
  const { tokenDistributerContract } = useContracts();
  const { run: runClaimToken, loading: claimTokenLoading } = useTransaction(
    tokenDistributerContract?.claimToken,
    {
      wait: true
    }
  );
  const chainId = useChainId();
  const { isMantaPacific, switchToMantaPacific } = useCheckNetwork();
  const { userInfo } = useUserInfo();
  const { user_address } = userInfo;
  const { userRankStatus } = useUserRank();
  const { data: rankDataRes, mutate: rankStatusMutate } = userRankStatus;
  const currentRank =
    rankDataRes?.data?.rank && rankDataRes?.data?.rank > 0
      ? rankDataRes?.data?.rank
      : '-';
  const { pointStatus } = usePointsStatus();
  const { claimableScores } = useClaimableToken();
  const {
    data: pointsDataRes,
    mutate: pointStatusMutate,
    isLoading: pointStatusLoading
  } = pointStatus;
  const { data: claimableScoresRes, mutate: claimableScoresMutate } =
    claimableScores;
  const claimableScoresData = claimableScoresRes?.data;
  const showClaimableScore =
    user_address && claimableScoresRes && claimableScoresRes?.code !== -2;

  const pointsData = pointsDataRes?.data;
  const showData = user_address && pointsDataRes && pointsDataRes?.code !== -2;
  const refreshStatus = () => {
    claimableScoresMutate();
    pointStatusMutate();
    rankStatusMutate();
  };
  const navLeaderboard = () => {
    setShowLeaderboard(true);
  };

  const getDigest = (address: string) =>
    ethers.utils.keccak256(ethers.utils.toUtf8Bytes(address.toLowerCase()));

  const checkClaimStatus = async () => {
    if (!address) return false;
    const digest = getDigest(address);
    try {
      const paused = await tokenDistributerContract?.paused();
      if (paused) setClaimPaused(true);
      // check claim status
      const status = await tokenDistributerContract?.claimStatus(digest);
      if (status) {
        setClaimedStatus(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Check claim status error:', error);
      return false;
    }
  };

  const handleClaim = async () => {
    setHandleClaimLoading(true);
    try {
      if (!address) {
        setHandleClaimLoading(false);
        return;
      }
      const digest = getDigest(address);
      const status = await checkClaimStatus();
      if (status) {
        notification.info({
          message: 'Already Claimed'
        });
        setHandleClaimLoading(false);
        return;
      }
      // sign claim signature
      const signRes = await fetcher(
        ENDPOINTS.claimToken,
        {
          method: 'POST'
        },
        false
      );
      const {
        receiver,
        tokenAmount,
        sig: { v, r, s }
      } = signRes?.data?.signature || {};
      // claim
      await runClaimToken(receiver, digest, tokenAmount, v, r, s);
      setTimeout(() => checkClaimStatus(), 200);
    } catch (error) {
      console.log('claim points error', error);
    } finally {
      setHandleClaimLoading(false);
    }
  };

  useEffect(() => {
    if (address && user_address) {
      if (claimedStatus) setClaimedStatus(false);
      if (claimPaused) setClaimPaused(false);
      checkClaimStatus();
    }
  }, [address, user_address]);

  const rankDisplay = (
    <div className="flex bg-white-80 justify-between items-center p-6 w-full border border-primary-green-border rounded-2xl">
      <div className="flex flex-col gap-2">
        <div className="text-[20px] text-black-title font-[400]">
          Current Rank
        </div>
        <div
          className="flex items-center gap-1 cursor-pointer"
          onClick={navLeaderboard}
        >
          <div className="text-[14px] text-green font-[500]">
            Check the Leaderboard
          </div>
          <img src={arrow} alt="arrow" />
        </div>
      </div>
      <div className="text-[40px] text-black-title font-[300]">
        {user_address ? currentRank : '-'}
      </div>
    </div>
  );
  if (showLeaderboard) {
    return (
      <LeaderBoardPage
        onBack={() => {
          setShowLeaderboard(false);
        }}
      />
    );
  }

  const ClaimButton = () => {
    if (claimPaused) {
      if (!(user_address && address)) {
        return (
          <button
            onClick={() => {
              showConnectModal();
            }}
            className="btn-primary h-[51px] w-full"
          >
            Connect Wallet
          </button>
        );
      }
      return (
        <div className="mt-4 w-full">
          <button className="btn-primary h-[51px] w-full" disabled>
            {isBeforeClaimStart() ? 'Calculating' : 'Expired'}
          </button>
        </div>
      );
    }
    if (!isMantaPacific && chainId && address) {
      return (
        <div className="mt-4 w-full">
          <button
            className="flex w-full justify-center btn-primary h-[51px] max-md:h-max"
            onClick={() => {
              switchToMantaPacific();
            }}
          >
            Switch to Manta Pacific
          </button>
        </div>
      );
    }
    return (
      <div className="mt-4 w-full">
        {user_address && address ? (
          <button
            onClick={() => handleClaim()}
            className="btn-primary h-[51px] w-full"
            disabled={claimedStatus || !claimableScoresData?.rewardTokenAmount}
          >
            {!showClaimableScore || handleClaimLoading || claimTokenLoading ? (
              <LoadingIcon />
            ) : (
              <>{!claimedStatus ? 'Claim' : 'Already Claimed'}</>
            )}
          </button>
        ) : (
          <button
            onClick={() => {
              showConnectModal();
            }}
            className="btn-primary h-[51px] w-full"
          >
            Connect Wallet
          </button>
        )}
      </div>
    );
  };

  function RoundPoints() {
    return (
      <div className="flex flex-col bg-white-80 justify-between items-center p-6 w-full border border-primary-green-border rounded-2xl">
        <div className="flex w-full items-center justify-between py-4 border-b border-[#05D9A699] border-dashed">
          <div className="flex flex-col justify-between">
            <div className="text-[20px] text-black-title font-[400]">
              Fourth Round Points
            </div>
          </div>
          <div className="text-[20px] text-black-title font-[300]">
            {showClaimableScore ? claimableScoresData?.scores || '- -' : '- -'}
          </div>
        </div>
        <div className="flex w-full items-center justify-between py-4 border-b border-[#05D9A699] border-dashed">
          <div className="flex flex-col justify-between">
            <div className="text-[20px] text-black-title font-[400]">
              Fourth Round Manta Rewards
            </div>
          </div>
          <div className="text-[40px] text-black-title font-[300]">
            {showClaimableScore
              ? claimableScoresData?.rewardTokenAmount || '- -'
              : '- -'}
          </div>
        </div>
        <ClaimButton />
        <div className="mt-2 w-full text-xs italic text-left text-[#1D314CB2]">
          Snapshot Time: Nov 30, 10PM SGT
        </div>
        <div className="mt-2 w-full text-xs italic text-left text-[#1D314CB2]">
          Claiming Period: Dec 2 10PM SGT - Dec 31 10PM SGT
        </div>
      </div>
    );
  }

  return (
    <>
      {/** PC */}
      <div className="flex gap-4 max-md:hidden pb-4">
        <div className="flex flex-col gap-4 items-start relative">
          <RoundPoints />
          <Boxes
            showConnectModal={showConnectModal}
            pointsData={pointsDataRes}
            user_address={user_address}
            mutateStatus={refreshStatus}
            pointStatusLoading={pointStatusLoading}
          />
        </div>
        <div className="flex w-full flex-col gap-4 items-start">
          <div className="flex flex-col bg-white-80 justify-between items-center p-6 w-full border border-primary-green-border rounded-2xl">
            <div className="flex w-full justify-between">
              <div className="flex flex-col justify-between">
                <div className="text-[20px] text-black-title font-[400]">
                  Fifth Round Points
                </div>
                <div className="flex items-start gap-2 relative">
                  <div className="gradient-text-1 text-[14px] font-[500] italic">
                    Current APR: 1%
                  </div>
                  <Popover
                    overlayInnerStyle={{
                      padding: '0',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      width: '295px'
                    }}
                    arrow={false}
                    placement="bottom"
                    content={() => (
                      <div className="bg-[#1D314C99] text-white p-2 rounded-[4px]">
                        Points will be exchangeable for Manta Tokens in the
                        future. The earnings from Points will be proportional to
                        the length of time M-Tokens are held.
                      </div>
                    )}
                    trigger="hover"
                  >
                    <img
                      src={infoIcon}
                      width={17}
                      height={17}
                      alt={'infoIcon'}
                    />
                  </Popover>
                </div>
              </div>
              <div className="text-[40px] text-black-title font-[300]">
                {showData ? pointsData?.total_scores || '- -' : '- -'}
              </div>
            </div>
            <div className="mt-2 text-sm text-black-title opacity-70">
              Points for the current round start accumulating immediately after
              the previous snapshot. You need to stake at least $100 or an
              equivalent amount in tokens to claim the reward points.
            </div>
          </div>
          {rankDisplay}
          <ExtraPoints />
        </div>
      </div>
      {/* H5 */}
      {isMobile && (
        <div className="flex flex-col items-center gap-4 md:hidden">
          <div className="w-full flex flex-col items-center">
            <RoundPoints />
            <MyPoint />
            <MTokenInfo />
          </div>
          <Boxes
            showConnectModal={showConnectModal}
            pointsData={pointsDataRes}
            user_address={user_address}
            mutateStatus={refreshStatus}
            pointStatusLoading={pointStatusLoading}
          />
          {rankDisplay}
          <ExtraPoints />
        </div>
      )}
    </>
  );
}

export default Points;
